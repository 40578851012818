<template>
  <div class="site-wrapper" :class="{ 'site-sidebar--fold': sidebarFold }">
    <main-navbar />
    <main-sidebar />
    <div
      class="site-content__wrapper"
      :style="{ 'min-height': documentClientHeight + 'px' }"
    >
      <main-content />
      <div class="icp-wrap">
        <div @click="goICP">粤ICP备号</div>
      </div>
    </div>
  </div>
</template>

<script>
import MainNavbar from './main-navbar';
import MainSidebar from './main-sidebar';
import MainContent from './main-content';

export default {
  components: {
    MainNavbar,
    MainSidebar,
    MainContent,
  },
  data() {
    return {};
  },
  computed: {
    documentClientHeight: {
      get() {
        return this.$store.state.common.documentClientHeight;
      },
      set(val) {
        this.$store.commit('common/updateDocumentClientHeight', val);
      },
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      },
    },
    userId: {
      get() {
        return this.$store.state.user.id;
      },
      set(val) {
        this.$store.commit('user/updateId', val);
      },
    },
    userName: {
      get() {
        return this.$store.state.user.name;
      },
      set(val) {
        this.$store.commit('user/updateName', val);
      },
    },
    companyId: {
      get() {
        return this.$store.state.user.companyId;
      },
      set(val) {
        this.$store.commit('user/updateCompanyId', val);
      },
    },
  },
  created() {
    this.getUserInfo();
  },
  mounted() {
    this.resetDocumentClientHeight();
  },
  methods: {
    goICP() {
      window.open('http://beian.miit.gov.cn');
    },
    // 重置窗口可视高度
    resetDocumentClientHeight() {
      this.documentClientHeight = document.documentElement['clientHeight'];
      window.onresize = () => {
        this.documentClientHeight = document.documentElement['clientHeight'];
      };
    },
    // 获取当前管理员信息
    getUserInfo() {
      this.$http({
        url: '/sys/user/info',
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.userId = data.user.userId;
          this.userName = data.user.userName;
          this.companyId = data.user.companyId;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.site-content__wrapper {
  display: flex;
  flex-direction: column;
  .icp-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      font-size: 12px;
      line-height: 23px;
      text-align: center;
      color: #333;
      cursor: pointer;
      &:hover {
        color: #666;
      }
    }
  }
  .site-content {
    flex: 1;
    overflow: hidden;
    padding: 0;
    .el-card {
      .el-card__body {
        padding: 0;
      }
    }
  }
  .site-content--tabs {
    padding: 45px 0 0;
  }
}

.listBtn {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  span {
    color: #000;
    line-height: 22px;
    flex: 0 0 20%;
    b {
      color: #f00;
      margin: 0 0 0 5px;
    }
  }
}
</style>
